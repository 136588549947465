import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../../components/header"
import Footer from "../../components/footer"
import "../../styles/style.css"

const Diseño_Arquitecturas = () => {
  const data = useStaticQuery(graphql`
    query Images_diseno_arquitecturas {
      image: allFile(
        filter: { relativeDirectory: { eq: "servicios" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Empresa Especialista en Diseño de Arquitecturas | Qualoom</title>
        <meta name="title" content="Empresa Especialista en Diseño de Arquitecturas | Qualoom"/>
        <meta name="description" content="Servicio completo de Infraestructura en Diseño de Arquitecturas. En Qualoom te Asesoramos en el proceso sin compromiso. ¡Infórmate YA!"/>
        <html lang='es'/>
        <link rel="alternate" href="https://www.qualoom.es/cloud-infraestructura/diseno-arquitecturas/"/>
        <link rel="canonical" href="https://www.qualoom.es/cloud-infraestructura/diseno-arquitecturas/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/cloud-infraestructura/diseno-arquitecturas/"/>
        <meta property="og:title" content="Empresa Especialista en Diseño de Arquitecturas | Qualoom"/>
        <meta property="og:description" content="Servicio completo de Infraestructura en Diseño de Arquitecturas. En Qualoom te Asesoramos en el proceso sin compromiso. ¡Infórmate YA!"/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/cloud-infraestructura/diseno-arquitecturas/"/>
        <meta property="twitter:title" content="Empresa Especialista en Diseño de Arquitecturas | Qualoom"/>
        <meta property="twitter:description" content="Servicio completo de Infraestructura en Diseño de Arquitecturas. En Qualoom te Asesoramos en el proceso sin compromiso. ¡Infórmate YA!"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/4Ew2pO6bm28Sji6rSXYTIV/0ef1dfe088fa1afe26106941a9d01234/tw_cards_cloud.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/cloud-infraestructura/diseno-arquitecturas/",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Cloud & Infraestructura", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/", "name": "Cloud & Infraestructura"}},
              {"@type":"ListItem", "position":"3", "name":"Diseño de arquitecturas", "item": {"@id": "https://www.qualoom.es/cloud-infraestructura/diseno-arquitecturas/", "name": "Diseño de arquitecturas"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Diseño de arquitecturas Cloud o de Software</h1>
              <p>Se trata de crear, configurar todos los servicios y herramientas necesarias para que las aplicaciones de las empresas puedan llevar a cabo su operativa diaria de la manera más eficiente posible. La correcta orquestación de servicios heterogéneos, la combinación de herramientas, su dimensionamiento y supervisión son parte del diseño de una arquitectura.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>¿Qué ofrece Qualoom?</h2><hr></hr>
          <h3>Diseño y evaluación de arquitecturas para tu negocio</h3>
          <p>Puede que el rendimiento de tus servidores estén infrautilizados y no les estés sacando el provecho adecuado (sobredimensionamiento) o puede que suceda lo contrario, que existan recurrentes problemas de rendimiento y degradaciones de servicio que se manifiestan con lentitud y caídas de tus aplicaciones. </p>
          <p>Quizás sea necesario incluir en tu ecosistema tecnológico una nueva pieza, componente o aplicación que requiera de un conocimiento profundo. <b>En Qualoom podemos ayudarte a diseñar la mejor arquitectura para tu negocio y su crecimiento futuro</b>, todo ello teniendo en cuenta los principales principios de arquitectura, diseño y calidad para que tu factura se corresponda con lo que realmente estás usando y tus sistemas de información no sufren degradaciones por una mala configuración de los mismos.</p>
        </div>
      </div>
      <div className="services-main gray">
        <div className="services-main-content">
          <h2>¿Qué problemas pueden ocurrir si no se tiene en cuenta el diseño de arquitecturas?</h2><hr></hr>
          <p>Existen infinidad de problemas cuando no se realiza un correcto diseño de arquitectura:</p>
          <ul>
            <li>Degradación o falta de rendimiento en aplicaciones, produciendo frustración y quejas en los usuarios</li>
            <li>Costes ocultos no estimados que son necesarios asumir para mantener un sistema que necesita más rendimiento</li>
            <li>Pérdidas de información por falta de previsión de copias de seguridad</li>
            <li>Brechas de seguridad por una clara ausencia de buenas prácticas y procedimientos así como configuraciones</li>
            <li>Otros</li>
          </ul>
          <br/>
          <div className="section-main">
            <div className="section-img arquitectura"></div>
            <div className="section-text">
              <h3>¿Cuál es la importancia del diseño de arquitectura software y sistemas?</h3>
              <p>La arquitectura en si, tanto de software como de sistemas de información, posee una gran importancia, ya que la manera en que esté estructurado un sistema tiene un impacto directo sobre la capacidad del mismo para satisfacer las necesidades requeridas.</p>
              <p><b>La capacidad de un sistema para satisfacer dichas necesidades se puede medir mediante los atributos de calidad</b> y rendimiento. Si tu organización requiere de expertos en administración de sistemas, diseño de arquitecturas altamente escalables, optimizar costes o resolver algún tipo de problemática de rendimiento. No dudes en ponerte en contacto con nosotros.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    
    
    
    
  )
}

export default Diseño_Arquitecturas